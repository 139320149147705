<template>
  <el-card class="box-card">
    <el-row>
      <el-descriptions class="margin-top" title="" :column="1">
        <el-descriptions-item label="付费会员人数">{{ row.number_of_people }}</el-descriptions-item>
      </el-descriptions>
    </el-row>
    <el-table
        :data="list"
        border
        size="small"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop=""
          label="月份">
      </el-table-column>
      <el-table-column
          align="center"
          prop=""
          label="现有会员">
      </el-table-column>
      <el-table-column align="center" label="新增 / 续费人数" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.name }} / {{ row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="新增 / 续费总金额" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.name }} / {{ row.name }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop=""
          label="到期未续费人数">
      </el-table-column>
      <el-table-column
          align="center"
          prop="state_order"
          label="付费会员产生的订单数">
      </el-table-column>
      <el-table-column
          align="center"
          prop="state_order"
          label="付费会员产生的订单总金额">
      </el-table-column>
      <el-table-column align="center" label="操作" width="80">
        <template #default="{ row }">
          <el-button type="text" @click="">查看明细</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
  </el-card>
</template>
<script>

export default {
  props: [],
  data() {
    return {
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
      },
      row: {
        number_of_people: '111',
      },
      list: [
        {
          id: '1',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '0',
        },
        {
          id: '2',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '0',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '1',
        },
        {
          id: '3',
          name: '益力多',
          pic: 'https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500',
          sale_price: '100',
          stock: '999',
          sales: '1',
          state: '1',
          create_at: '1647584613',
          merchant_id: '1',
          is_check: '2',
        },
      ],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      const {data} = await getList(this.queryForm)
      this.list = data.list
      this.count = data.count
      this.count = parseInt(data.count)
      if (!this.list.length) this.queryForm.page++
      this.listLoading = false
    },
  }
}

</script>
<style>
.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
